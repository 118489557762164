import { ProductName } from '@pypestream/api-services';
import { logos } from '@pypestream/design-system';

import { ManagerContext, Project } from '../xstate/manager.xstate.types';

export type Product = {
  name: ProductName;
  label: string;
  logo: keyof typeof logos;
  productId: string;
  disabled: boolean;
  url?: string;
  index?: number;
  conditional?: boolean;
  integrated?: boolean;
};

export interface GetProductsProps {
  ctx: ManagerContext;
  project?: Project;
}

interface GetProductsResult {
  products: Product[];
  conditionalProducts: Product[];
  selectedProjectURL: string | undefined;
  hasAvailableProduct: boolean;
}

export interface GetProducts {
  (props: GetProductsProps): GetProductsResult;
}

export const initialTools: Product[] = [
  {
    name: ProductName.Organization,
    label: 'Manager',
    logo: 'org',
    url: undefined,
    disabled: true,
    index: 1,
    productId: '',
  },
  {
    name: ProductName.AgentAssist,
    label: 'Contact Center',
    logo: 'agentAssist',
    url: undefined,
    conditional: true,
    disabled: true,
    index: 2,
    productId: '',
  },
  {
    name: ProductName.Analytics,
    label: 'Analytics',
    logo: 'analytics',
    url: undefined,
    conditional: true,
    disabled: true,
    index: 3,
    productId: '',
  },
  {
    name: ProductName.ProStudio,
    label: 'Pro Studio',
    logo: 'proStudio',
    url: undefined,
    conditional: true,
    disabled: true,
    index: 4,
    productId: '',
  },
];

export const getProducts: GetProducts = ({
  project,
  ctx: { userInfo, allProducts, projects, routes, userProjectIds },
}) => {
  const initialProjects = projects?.filter((p) =>
    userProjectIds.includes(p.projectId)
  );

  const transformCtxValues = () => ({
    productIds: allProducts?.reduce(
      (acc, { name, id }) => ({ ...acc, [`${name}`]: id }),
      {} as Record<ProductName, string>
    ),
    enabledTools: [
      ...new Set(
        initialProjects
          ?.map((p) => {
            return p?.projectProductSettings
              ?.map((setting) => setting.productId)
              ?.flat();
          })
          ?.flat()
      ),
    ],
  });

  const { productIds, enabledTools } = transformCtxValues();

  const isPypeEmployee = Boolean(userInfo?.isPypestreamEmployee);

  const projectProducts =
    project?.projectProductSettings?.map(({ productId }) => productId) || [];

  const projectId = project?.projectId;
  // @todo remove contactCenterTempId after fixes from the CC side
  let contactCenterTempId = project?.agentAssistAccountId || project?.projectId;

  // If there is only one project for Contact Center tool,
  // selecting a Contact Center tool card on Home should bring you to the corresponding Project instead of showing a modal.
  const agentAssistProjects =
    initialProjects?.filter((p) => {
      return p?.projectProductSettings?.some(
        (s) => s.productId === productIds?.[ProductName.AgentAssist]
      );
    }) || [];
  if (agentAssistProjects.length === 1) {
    // @todo remove contactCenterTempId after fixes from the CC side
    contactCenterTempId =
      agentAssistProjects[0].agentAssistAccountId ||
      agentAssistProjects[0].projectId ||
      '';
  }

  const hasAgentAssistRoles = Boolean(
    projectId
      ? userInfo?.productRoles?.agentAssistRolesSource?.some(
          ({ project: p }) => p?.id === projectId
        )
      : userInfo?.productRoles?.hasAgentAssistRoles
  );

  let analyticsProjectId;
  const analyticsProjects =
    initialProjects?.filter((p) => {
      return p?.projectProductSettings?.some(
        (s) => s.productId === productIds?.[ProductName.Analytics]
      );
    }) || [];

  if (analyticsProjects.length === 1) {
    analyticsProjectId = analyticsProjects[0].projectId;
  }

  const hasAnalyticsRoles = Boolean(
    projectId
      ? userInfo?.productRoles?.analyticsRolesSource?.some(
          ({ project: p }) => p?.id === projectId
        )
      : userInfo?.productRoles?.hasAnalyticsRoles
  );

  let proStudioProjectId;
  const proStudioProjects =
    initialProjects?.filter((p) =>
      p?.projectProductSettings?.some(
        (s) => s.productId === productIds?.[ProductName.ProStudio]
      )
    ) || [];

  if (proStudioProjects.length === 1) {
    proStudioProjectId = proStudioProjects[0].projectId;
  }

  const hasProStudioRoles = Boolean(
    projectId
      ? userInfo?.productRoles?.proStudioRolesSource?.some(
          ({ project: p }) => p?.id === projectId
        )
      : userInfo?.productRoles?.hasProStudioRoles
  );

  const commonProductsProps: Omit<Product, 'productId'>[] = [
    {
      name: ProductName.Organization,
      label: 'Manager',
      logo: 'org',
      url: routes.users,
      disabled: false,
      index: 1,
    },
    {
      name: ProductName.AgentAssist,
      label: 'Contact Center',
      logo: 'agentAssist',
      url:
        hasAgentAssistRoles && contactCenterTempId
          ? `${
              import.meta.env.FE_AGENT_ASSIST_URL
            }/app/accounts/${contactCenterTempId}/dashboard`
          : undefined,
      conditional: true,
      disabled: !hasAgentAssistRoles,
      index: 2,
    },
    {
      name: ProductName.Analytics,
      label: 'Analytics',
      logo: 'analytics',
      url:
        analyticsProjectId || projectId
          ? `${import.meta.env.FE_ANALYTICS_URL}/api/projects/${analyticsProjectId || projectId}/change`
          : undefined,
      conditional: true,
      disabled: !hasAnalyticsRoles,
      index: 3,
    },
    {
      name: ProductName.ProStudio,
      label: 'Pro Studio',
      logo: 'proStudio',
      url:
        proStudioProjectId || projectId
          ? `${import.meta.env.FE_PRO_STUDIO_URL}?projectId=${proStudioProjectId || projectId}`
          : undefined,
      conditional: true,
      disabled: !hasProStudioRoles,
      index: 4,
    },
  ];

  const products: Product[] = commonProductsProps
    .map(({ conditional, disabled, url, name, ...restProps }) => {
      const productId = productIds?.[name] || '';
      const integrated = project ? projectProducts?.includes(productId) : false;
      const isDisabled = !productId
        ? true
        : conditional
          ? disabled ||
            (project ? !integrated : !enabledTools.includes(productId))
          : disabled;

      return {
        ...restProps,
        name,
        url: isDisabled ? undefined : url,
        productId,
        conditional,
        disabled: isDisabled,
        ...(project &&
          conditional && {
            integrated,
          }),
      };
    })
    .sort(
      ({ index: prevIndex }, { index: nextIndex }) =>
        Number(prevIndex) - Number(nextIndex)
    );

  const conditionalProducts: Product[] = products.filter(
    ({ conditional }) => conditional
  );

  const getSelectedProjectURL = () => {
    if (!productIds) return;

    const productUrls = conditionalProducts.filter(({ url }) => url);

    return productUrls.length > 1
      ? undefined
      : productUrls.length === 1
        ? productUrls[0].url
        : products.find(
            ({ productId }) =>
              productId === productIds[ProductName.Organization]
          )?.url;
  };

  const hasAvailableProduct = !project
    ? false
    : project.projectProductSettings?.some((item) =>
        products
          .filter(({ disabled }) => !disabled)
          .some((p) => p.productId === item.productId)
      ) || isPypeEmployee;

  return {
    products,
    conditionalProducts,
    selectedProjectURL: getSelectedProjectURL(),
    hasAvailableProduct,
  };
};
