import React, { FC, useCallback, useEffect } from 'react';
import { IPage } from '../../types';
import {
  Button,
  PageBody,
  Stack,
  TextTitle,
  Spacer,
} from '@pypestream/design-system';
import { OrganizationRoleNames, useAuthRole } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatchesAllOf,
} from '../../../xstate/app.xstate';
import { useForm } from 'react-hook-form';
import { OrganizationDetails } from './organization-details';
import { TranslationComponent } from '@pypestream/translations';

export type FormOrgInfoType = {
  name: string;
  authProvider: string;
  picture?: string;
  accountIconId?: string;
  accountManagerId?: string;
};

export const CreateOrganization: FC<IPage> = () => {
  const navigate = useNavigate();
  const isReadyToLoad = useManagerStateMatchesAllOf([
    'orgRelated.ready.users.idle',
    'orgRelated.ready.currentOrg.selected',
  ]);

  const { routes, users, organizationId, parentOrg } = useManagerCtxSelector(
    (ctx) => ({
      users: ctx.users,
      routes: ctx.routes,
      organizationId: ctx.selectedOrgId,
      parentOrg: ctx.orgs?.find(({ id }) => id === ctx.selectedOrgId),
    })
  );

  const isAdminRole = useAuthRole([
    OrganizationRoleNames.ADMIN,
    OrganizationRoleNames.SUPER_ADMIN,
  ]);

  const navigateToChildOrgs = useCallback(
    () => navigate(routes.orgs),
    [navigate, routes.orgs]
  );

  const form = useForm<FormOrgInfoType>({
    defaultValues: {
      name: '',
      authProvider: '',
      picture: '',
      accountIconId: '',
      accountManagerId: '',
    },
  });

  const onSubmit = (values: FormOrgInfoType) => {
    const authProvider =
      values.authProvider === 'same-as-parent'
        ? parentOrg?.settings?.security?.authProvider
        : values.authProvider;

    sendManagerEvent({
      type: 'manager.addOrg',
      accountName: values.name,
      picture: values.picture,
      securitySettings: {
        authProvider,
      },
      accountIconId: values.accountIconId,
      callback: (res) => {
        if (res) {
          sendManagerEvent('manager.childOrgs.loadChildOrgs');
          navigateToChildOrgs();
        }
      },
    });
  };

  useEffect(() => {
    if (isReadyToLoad) {
      sendManagerEvent('manager.users.loadUsers');
    }
  }, [isReadyToLoad]);

  return (
    <PageBody background="none">
      <TextTitle
        test-id="create-org-title"
        i18nKey="manager/organizations:createOrg.title"
      >
        <TranslationComponent i18nKey="manager/organizations:createOrg.title">
          Create Child Org
        </TranslationComponent>
      </TextTitle>
      <form>
        <Spacer size="large" />
        <OrganizationDetails
          form={form}
          parentId={organizationId || ''}
          users={users || []}
          isAdminRole={isAdminRole}
          isEdit={false}
        />
        {isAdminRole && (
          <Stack justifyContent="end" gutter="small">
            <Button
              test-id="cancel-creation-org-btn"
              variant="secondary"
              size="large"
              onClick={() => {
                form.reset();
                navigateToChildOrgs();
              }}
            >
              <TranslationComponent i18nKey="manager/organizations:createOrg.cancel">
                Cancel
              </TranslationComponent>
            </Button>
            <Button
              test-id="create-org-btn"
              size="large"
              onClick={form.handleSubmit(onSubmit)}
              disabled={!Object.keys(form.formState.dirtyFields).length}
            >
              <TranslationComponent i18nKey="manager/organizations:createOrg.create">
                Create Child Org
              </TranslationComponent>
            </Button>
          </Stack>
        )}
      </form>
    </PageBody>
  );
};
