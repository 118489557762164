/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import {
  GetUserProjectsLimitedQuery,
  UserStatus,
} from '@pypestream/api-services/urql';
import { sort, transformTimezonesData } from '@pypestream/utils';
import { inspect } from '@xstate/inspect';
import { initialize } from 'launchdarkly-js-client-sdk';
import {
  assign,
  interpret,
  Interpreter,
  Machine,
  Sender,
  State,
  StateMachine,
} from 'xstate';

import {
  DefaultFeatureFlags,
  WindowWithPypestreamGlobals,
} from '../../feature-flags/feature-flags.default';
import {
  AvailableFeatureFlags,
  FeatureFlagType,
} from '../../feature-flags/feature-flags.types';
import { CommonProject } from '../universal-nav/types';
import { urqlClient } from '../urql-client';
import { hasErrors } from '../utils';
import {
  Language,
  processUserProjects,
  SmartContext,
  SmartEvents,
  SmartState,
  SmartTypestates,
  Timezone,
} from './smart.xstate-utils';

const win = window as WindowWithPypestreamGlobals;

const isValidFlag = (flagKey: any): boolean => {
  const isValid = flagKey in DefaultFeatureFlags;

  if (!isValid) {
    // @todo - Need to log this with datadog once available.
    // Also, do we need to log app version too?
    console.log('Unhandled feature flag :- ', flagKey);
  }
  return isValid;
};

// @todo: update to support overrides (for debugging) via external super admin controls
const enableStateInspector = false;
if (enableStateInspector) {
  inspect({
    url: 'https://stately.ai/viz?inspect=1',
    iframe: false,
  });
}

const entryLogsAvalable = false;

const logOnEntryState = (stateName: string, info?: unknown) => {
  if (!entryLogsAvalable) return;
  console.log(
    `%csmart-state: ${stateName}`,
    'color: purple; font-size: larger; font-weight: bold',
    info || ''
  );
};

export type SmartMachineState = State<
  SmartContext,
  SmartEvents,
  any,
  SmartTypestates,
  any
>;

export const smartMachine: StateMachine<SmartContext, SmartState, SmartEvents> =
  Machine<SmartContext, SmartState, SmartEvents>(
    {
      predictableActionArguments: true,
      preserveActionOrder: true,
      id: 'smart-components',
      context: {
        app: undefined,
        accountId: undefined,
        userInfo: undefined,
        userSettings: {},
        assignedProjects: undefined,
        allProducts: undefined,
        hasAvailableTools: undefined,
        languages: [],
        timezones: [],
        featureFlags: undefined,
        projectsLimitedUnsubscribe: undefined,
      },
      type: 'parallel',
      invoke: [
        {
          id: 'initializing-feature-flags',
          src: (ctx) => async (sendEvent: Sender<SmartEvents>) => {
            if (ctx.featureFlags) {
              return Promise.resolve();
            }

            if (Object.keys(DefaultFeatureFlags).length) {
              const ldApiKey =
                win.LAUNCH_DARKLY_API_KEY || LAUNCH_DARKLY_API_KEY || '';
              const client = initialize(
                ldApiKey,
                {
                  kind: 'user',
                  anonymous: true,
                },
                {
                  // This is to prevent sending many events from app to launchDarkly.
                  // @todo - Need to check how elegantly we can handle this as w.r.t. launch darkly docs, this might break some features on LD side. (features like, reports, ld logs and all) --> not good for AB testing.
                  flushInterval: 900000,
                }
              );

              client.on('change', (updatedFlags) => {
                Object.keys(updatedFlags).forEach((updatedFlagKey) => {
                  if (isValidFlag(updatedFlagKey)) {
                    sendEvent({
                      type: 'featureFlags.update',
                      // isValidFlag check is making sure that this typecast is correct
                      // @todo - Ideally we should not need this typecast, need to check for proper fix
                      updatedFlag: updatedFlagKey as AvailableFeatureFlags,
                      updatedValue: updatedFlags[updatedFlagKey].current,
                    });
                  }
                });
              });

              return client
                .waitForInitialization()
                .then(() => {
                  sendEvent({
                    type: 'featureFlags.initialized',
                    flags: Object.fromEntries(
                      Object.entries(client.allFlags()).filter(([key]) =>
                        isValidFlag(key)
                      )
                    ) as FeatureFlagType,
                  });
                })
                .catch(() => {
                  sendEvent({
                    type: 'featureFlags.initializedWithDefaultValues',
                  });
                  // @todo - Need to log this with datadog once available.
                  console.log(
                    'Initialized application with build-time/default-flags values in smart-context.'
                  );
                });
            }
            // @todo - Need to log this with datadog
            console.warn(
              "Bypassed LD-initialization as we didn't had FFs build time."
            );
            return Promise.resolve();
          },
        },
      ],
      on: {
        updateUserInfo: {
          actions: assign((_ctx, event) => {
            const { userInfo } = event;

            return {
              userInfo: {
                ..._ctx.userInfo,
                ...userInfo,
                status: userInfo.status || UserStatus.Invited,
                email: _ctx.userInfo?.email || '',
                defaultAccount: {
                  ..._ctx.userInfo?.defaultAccount,
                  id: userInfo.accountId,
                },
              },
            };
          }),
        },
        changeApp: {
          actions: assign((_ctx, event) => ({
            app: event.app,
          })),
        },
        changeOrg: [
          {
            cond: (ctx, _event) => !ctx.userInfo,
            actions: assign((_ctx, event) => ({
              accountId: event.org,
              assignedProjects: undefined,
              hasAvailableTools: true,
            })),
            target: 'userInfo.loading',
          },
          {
            cond: (ctx, _event) => !!ctx.userInfo,
            actions: assign((_ctx, event) => ({
              accountId: event.org,
              assignedProjects: undefined,
              hasAvailableTools: true,
            })),
            target: 'projects.loading',
          },
        ],
        logout: {
          target: 'logout.loading',
        },
      },
      states: {
        featureFlags: {
          initial: 'idle',
          states: {
            idle: {
              entry: (ctx, event) =>
                logOnEntryState('featureFlags_idle', { ctx, event }),
              on: {
                'featureFlags.initialized': {
                  target:
                    '#smart-components.featureFlags.loaded.withActualValue',
                  actions: [
                    assign((_ctx, data) => ({ featureFlags: data.flags })),
                  ],
                },
                'featureFlags.initializedWithDefaultValues': {
                  target:
                    '#smart-components.featureFlags.loaded.withDefaultValues',
                  actions: [
                    assign((_ctx) => ({ featureFlags: DefaultFeatureFlags })),
                  ],
                },
              },
            },
            loaded: {
              entry: (ctx, event) =>
                logOnEntryState('featureFlags_loaded', { ctx, event }),
              on: {
                'featureFlags.update': {
                  actions: assign((ctx, data) => {
                    let updatedContext = {
                      featureFlags: ctx.featureFlags,
                    };
                    if (
                      ctx.featureFlags &&
                      data.updatedFlag in DefaultFeatureFlags
                    ) {
                      updatedContext = {
                        featureFlags: {
                          ...ctx.featureFlags,
                          [data.updatedFlag]: data.updatedValue,
                        },
                      };
                    }
                    return updatedContext;
                  }),
                },
              },
              states: {
                withActualValue: {
                  entry: (ctx, event) =>
                    logOnEntryState('featureFlags_withActualValue', {
                      ctx,
                      event,
                    }),
                },
                withDefaultValues: {
                  entry: (ctx, event) =>
                    logOnEntryState('featureFlags_withDefaultValues', {
                      ctx,
                      event,
                    }),
                },
              },
            },
          },
        },
        userInfo: {
          initial: 'loading',
          states: {
            idle: {
              entry: (ctx, event) =>
                logOnEntryState('userInfo_idle', { ctx, event }),
              always: {
                target: 'loading',
              },
            },
            loading: {
              entry: (ctx, event) =>
                logOnEntryState('userInfo_loading', { ctx, event }),
              invoke: {
                id: 'getUserInfo',
                src: async (_ctx) => {
                  const { data: user, error: userInfoError } = await (
                    await urqlClient()
                  ).getUserInfoLimited();

                  if (hasErrors(user)) {
                    throw new Error(user.errors?.[0]?.message);
                  }

                  if (userInfoError) {
                    throw new Error(
                      `Failed to fetch user info: ${userInfoError.message}`
                    );
                  }

                  const userId = user?.admin_?.currentUser?.id;
                  const email = user?.admin_?.currentUser?.email;

                  if (!userId || !email) {
                    throw new Error(
                      `No user id found: ${userId} or email: ${email}`
                    );
                  }

                  const { data: userInfo, error: userSettingsError } = await (
                    await urqlClient()
                  ).getUserSettingsLimited({ email });

                  if (hasErrors(userInfo)) {
                    throw new Error(userInfo.errors?.[0]?.message);
                  }

                  if (userSettingsError) {
                    throw new Error(
                      `Failed to fetch user settings: ${userSettingsError.message}`
                    );
                  }

                  return {
                    userInfo: user?.admin_?.currentUser,
                    userSettings: userInfo?.admin_,
                    defaultAccountId:
                      user?.admin_?.currentUser?.defaultAccount?.id,
                  };
                },
                onDone: {
                  target: ['loaded'],
                  actions: assign((ctx, event) => {
                    const { userInfo, userSettings, defaultAccountId } =
                      event.data;

                    return {
                      userInfo,
                      userSettings,
                      ...(!ctx.accountId && {
                        accountId: defaultAccountId,
                      }),
                    };
                  }),
                },
                onError: {
                  target: 'loadError',
                },
              },
            },
            loaded: {
              entry: (ctx, event) => [
                logOnEntryState('userInfo_loaded', { ctx, event }),
                smartService.send('loadUserProjects'),
              ],
              on: {
                updateUser: 'updating',
                loadUser: 'loading',
              },
            },
            loadError: {
              entry: (ctx, event) =>
                logOnEntryState('userInfo_loadError', { ctx, event }),
            },
            updating: {
              entry: (ctx, event) =>
                logOnEntryState('userInfo_updating', { ctx, event }),
              id: 'updatingUser',
              invoke: {
                src: async (
                  ctx,
                  event
                ): Promise<void | {
                  callback: ((res: boolean) => void) | undefined;
                  userInfo: SmartContext['userInfo'];
                }> => {
                  if (event.type !== 'updateUser') {
                    return {
                      callback: undefined,
                      userInfo: ctx.userInfo,
                    };
                  }

                  const { userInfo } = event;
                  const { data, error: userUpdateError } = await (
                    await urqlClient()
                  ).updateUserLimited(userInfo);

                  if (hasErrors(data)) {
                    console.error(data.errors?.[0]?.message);
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (userUpdateError) {
                    console.error(userUpdateError);
                    throw new Error(
                      `Failed to update user: ${userUpdateError.message}`
                    );
                  }

                  const updatedUserInfo: SmartContext['userInfo'] = {
                    ...ctx.userInfo,
                    ...userInfo,
                    status: userInfo.status || UserStatus.Active,
                    email: ctx.userInfo?.email || '',
                    defaultAccount: {
                      ...ctx.userInfo?.defaultAccount,
                      id: userInfo.accountId,
                    },
                  };

                  return {
                    callback: event.callback,
                    userInfo: updatedUserInfo,
                  };
                },
                onDone: {
                  target: 'loaded',
                  actions: assign((_ctx, event) => {
                    const { callback, userInfo } = event.data;
                    callback(true);
                    return {
                      userInfo,
                    };
                  }),
                },
                onError: {
                  target: 'loaded',
                },
              },
            },
          },
        },
        projects: {
          initial: 'idle',
          id: 'projects',
          on: {
            loadUserProjects: {
              target: '.loading',
              cond: (ctx) =>
                ctx.userInfo?.id !== undefined && ctx.accountId !== undefined,
            },
            processProjectsSubscriptionUpdate: {
              actions: assign((ctx, event) => {
                if (event.type !== 'processProjectsSubscriptionUpdate') {
                  return ctx;
                }

                ctx.assignedProjects = processUserProjects({
                  projects: event.data?.projects,
                  allProducts: ctx.allProducts || [],
                  accountId: ctx.accountId || '',
                });
                return ctx;
              }),
            },
          },
          states: {
            idle: {},
            loading: {
              id: 'loadingProjects',
              invoke: {
                src: async (
                  ctx
                ): Promise<{
                  products: GetUserProjectsLimitedQuery['tenancy_products'];
                  assignedProjects: CommonProject[];
                }> => {
                  const client = await urqlClient();
                  const {
                    data: assignedUserProjects,
                    error: assignedUserProjectsError,
                  } = await client.getUserProjectsLimited({
                    userId: ctx.userInfo?.id as string,
                  });
                  if (assignedUserProjectsError) {
                    throw new Error(
                      `Failed to fetch user projects: ${assignedUserProjectsError.message}`
                    );
                  }

                  if (!assignedUserProjects) {
                    throw new Error('No user projects found');
                  }

                  const assignedProjects = processUserProjects({
                    projects: assignedUserProjects.tenancy_users_by_pk,
                    allProducts: assignedUserProjects.tenancy_products,
                    // @todo - Add to query
                    accountId: ctx.accountId || '',
                  });
                  return {
                    products: assignedUserProjects.tenancy_products,
                    assignedProjects,
                  };
                },
                onDone: {
                  target: 'setupSubscription',
                  actions: [
                    assign((_ctx, event) => {
                      const { products } = event.data;
                      return {
                        allProducts: products,
                        assignedProjects: event.data.assignedProjects,
                      };
                    }),
                  ],
                },
                onError: {
                  target: 'loadError',
                },
              },
            },
            setupSubscription: {
              initial: 'settingUp',
              states: {
                settingUp: {
                  invoke: {
                    src: async (ctx) => {
                      if (ctx.projectsLimitedUnsubscribe) {
                        ctx.projectsLimitedUnsubscribe();
                      }
                      const client = await urqlClient();
                      const { subscribe } = client.projectsSubscriptionLimited({
                        userId: ctx.userInfo?.id as string,
                      });
                      const { unsubscribe } = subscribe((subData) => {
                        if (subData.error || !subData.data) {
                          throw new Error(
                            `Something went wrong with subscription setup: ${subData.error?.message}`
                          );
                        } else {
                          smartService.send(
                            'processProjectsSubscriptionUpdate',
                            {
                              data: {
                                projects: subData.data.tenancy_users_by_pk,
                              },
                            }
                          );
                        }
                      });
                      return unsubscribe;
                    },
                    onDone: {
                      target: 'completed',
                      actions: assign((_ctx, event) => ({
                        projectsLimitedUnsubscribe: event.data,
                      })),
                    },
                    onError: {
                      target: 'error',
                    },
                  },
                },
                completed: {},
                error: {},
              },
            },
            loadError: {},
          },
        },
        languages: {
          id: 'languages',
          initial: 'loading',
          states: {
            loading: {
              entry: (ctx, event) =>
                logOnEntryState('languages_loading', { ctx, event }),
              id: 'languagesLoading',
              invoke: {
                id: 'getLanguages',
                src: async () => {
                  const { data: languages, error: languagesError } = await (
                    await urqlClient()
                  ).getLanguagesLimited();

                  if (hasErrors(languages)) {
                    throw new Error(languages.errors?.[0]?.message);
                  }

                  if (languagesError) {
                    throw new Error(
                      `Failed to fetch languages: ${languagesError.message}`
                    );
                  }

                  return {
                    locales: languages?.admin_?.locales || [],
                    localizationSettingsConfig:
                      languages?.admin_?.localizationSettingsConfig,
                  };
                },
                onDone: {
                  target: 'loaded',
                  actions: assign((ctx, event) => {
                    const { locales, localizationSettingsConfig } = event.data;
                    const languages: Language[] = sort<Language>(locales)
                      .asc('name')
                      // .filter(
                      //   ({ languageCode }) =>
                      //     !languageCode ||
                      //     (
                      //       localizationSettingsConfig?.user
                      //         .supportedLanguageCodes || []
                      //     ).includes(languageCode)
                      // )
                      .filter(
                        ({ languageCode }) =>
                          !languageCode ||
                          // ctx.localizationSettingsConfig?.user.supportedLanguageCodes || []
                          // @TODO: Need this for November's 2024 demo (Arabic and English US only)
                          ['en', 'ar'].includes(languageCode)
                      )
                      // @TODO: Need this for November's 2024 demo (Arabic and English US only)
                      .filter(
                        ({ locale }) =>
                          locale === 'ar-AE' ||
                          locale === 'en-US' ||
                          locale === ''
                      );

                    return {
                      languages,
                    };
                  }),
                },
                onError: {
                  target: 'loadError',
                },
              },
            },
            loaded: {
              entry: (ctx, event) =>
                logOnEntryState('languages_loaded', { ctx, event }),
            },
            loadError: {
              entry: (ctx, event) =>
                logOnEntryState('languages_loadError', { ctx, event }),
            },
          },
        },
        timezones: {
          id: 'timezones',
          initial: 'loading',
          states: {
            loading: {
              entry: (ctx, event) =>
                logOnEntryState('timezones_loading', { ctx, event }),
              id: 'timezonesLoading',
              invoke: {
                id: 'getTimezones',
                src: async () => {
                  const { data: timezones, error: timezonesError } = await (
                    await urqlClient()
                  ).getTimeZonesLimited();

                  if (hasErrors(timezones)) {
                    throw new Error(timezones.errors?.[0]?.message);
                  }

                  if (timezonesError) {
                    throw new Error(
                      `Failed to fetch timezones: ${timezonesError.message}`
                    );
                  }

                  const timeZones = timezones?.admin_?.timeZones || [];

                  return transformTimezonesData<Timezone>(timeZones);
                },
                onDone: {
                  target: 'loaded',
                  actions: assign((ctx, event) => {
                    const timezones = sort<Timezone>(event.data).asc('label');

                    return {
                      timezones,
                    };
                  }),
                },
                onError: {
                  target: 'loadError',
                },
              },
            },
            loaded: {
              entry: (ctx, event) =>
                logOnEntryState('timezones_loaded', { ctx, event }),
            },
            loadError: {
              entry: (ctx, event) =>
                logOnEntryState('timezones_loadError', { ctx, event }),
            },
          },
        },
        logout: {
          id: 'logout',
          initial: 'idle',
          states: {
            idle: {
              entry: (ctx, event) =>
                logOnEntryState('logout_idle', { ctx, event }),
            },
            loading: {
              entry: (ctx, event) =>
                logOnEntryState('logout_loading', { ctx, event }),
              invoke: {
                src: async (_ctx, event) => {
                  if (event.type !== 'logout') {
                    return;
                  }

                  const { data, error } = await (
                    await urqlClient()
                  ).falsifyUserActiveSessionsLimited();

                  if (hasErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(
                      `Failed to perform logout hook: ${error.message}`
                    );
                  }

                  event.callback();
                },
                onDone: {
                  target: 'loaded',
                },
                onError: {
                  target: 'error',
                },
              },
            },
            loaded: {
              entry: (ctx, event) =>
                logOnEntryState('logout_loaded', { ctx, event }),
            },
            error: {
              entry: (ctx, event) =>
                logOnEntryState('logout_error', { ctx, event }),
            },
          },
        },
      },
    },
    {
      actions: {},
    }
  );

export const smartService: Interpreter<
  SmartContext,
  SmartState,
  SmartEvents,
  SmartTypestates,
  any
> = interpret<SmartContext, SmartState, SmartEvents, SmartTypestates, any>(
  smartMachine,
  {
    devTools: enableStateInspector,
  }
);

smartService.start();

win.pypestream = {
  smartService,
};
