import {
  IconButton,
  Scrollbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';

import { FC } from 'react';
import { ProjectItem } from '../../components';
import { sendManagerEvent } from '../../xstate/app.xstate';
import { ManagerContext } from '../../xstate/manager.xstate.types';

interface ProjectTableProps {
  projects: ManagerContext['projects'];
}

export const ProjectsTable: FC<ProjectTableProps> = ({ projects }) => {
  return (
    <Scrollbar forceVisible disableAutoHide>
      <Table test-id="home-page-projects-table">
        <TableHead>
          <TableRow>
            <TableCell variant="th">
              <TranslationComponent i18nKey="manager/common:homepage.projects.project">
                Project
              </TranslationComponent>
              <IconButton size="xsmall" name="chevron-down" slot="sorter" />
            </TableCell>
            <TableCell variant="th" sortActive>
              <TranslationComponent i18nKey="manager/common:homepage.projects.tools">
                Tools
              </TranslationComponent>
              <IconButton size="xsmall" name="chevron-down" slot="sorter" />
            </TableCell>
            <TableCell variant="th" align="start">
              <TranslationComponent i18nKey="manager/common:homepage.projects.lastUpdated">
                Last updated
              </TranslationComponent>
              <IconButton size="xsmall" name="chevron-down" slot="sorter" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects?.map((item) => (
            <ProjectItem
              key={item.projectId}
              item={item}
              onClick={() =>
                sendManagerEvent({
                  type: 'manager.selectProjectModal',
                  id: item.projectId,
                })
              }
            />
          ))}
        </TableBody>
      </Table>
    </Scrollbar>
  );
};
