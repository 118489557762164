/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-console */
import { ProductName } from '@pypestream/api-services';
import { allLocales, TranslationData } from '@pypestream/translations';
import { css, html } from 'lit';
import { property } from 'lit/decorators.js';

import {
  BaseElement,
  customElement,
  watch,
} from '../../components/base-element';
import { Translate } from '../../components/base-element/mixins/translation-mixin';
import { getStateChanges, handleGoHome } from '../utils';
import { smartService } from '../xstate/smart.xstate';
import { SmartContext } from '../xstate/smart.xstate-utils';

import '../../components/buttons/icon-button/icon-button.wc';
import '../../components/menu/src/menu-item.wc';
import '../../components/nav/nav-link.wc';
import '../../components/nav/nav-links.wc';
import '../../components/nav/nav-logo.wc';
import '../../components/nav/nav.wc';
import '../mega-menu/mega-menu.wc';
import '../universal-user-dropdown/universal-user-dropdown-trigger.wc';
import '../universal-user-dropdown/universal-user-dropdown.wc';

const navTranslations = allLocales.filter(
  (locale) => locale.namespace === 'navigation'
) as unknown as TranslationData[];

/**
 * ### Component Description ###
 * `ps-universal-nav` is a component that provides a navigation bar with support for:
 *  - *logo*
 *  - *navigation links*
 *  - *additional actions*
 *  - *mega-menu*
 *  - *profile dropdown*
 *
 *
 * ### Features ###
 * The component fetches necessary data (user information, projects, products and their roles) to display information in ***Mega Menu***
 *
 * The component fetches user information and displays the *user's name*, *email*, and *profile photo* in the ***Profile Dropdown***
 */
@customElement('ps-universal-nav')
export class UniversalNavWC extends Translate(BaseElement) {
  static styles = css`
    :host {
      display: contents;
    }
  `;

  context: SmartContext;

  @property({ type: Array }) translations: TranslationData[] | undefined =
    navTranslations;

  @watch('translations')
  onTranslationsChanged() {
    if (this.translations?.length) {
      // use method from translation mixin to add external translations to i18next
      // @ts-ignore
      this.loadTranslations(this.translations);
    }
  }

  /**
   * current ***App name*** (ProductName.Organization | ProductName.AgentAssist | ProductName.Analytics)
   */
  @property({ reflect: true }) app: ProductName = ProductName.Organization;

  /**
   * current ***Organization id***
   */
  @property({ reflect: false }) org?: string;

  /**
   * current project
   */
  @property({ reflect: false, type: Object }) project?: {
    name: string;
    id: string;
    icon: string;
  };

  /**
   * optional setting to hide the "go back home" link that normally shows up
   */
  @property({ reflect: false, type: Boolean }) hideGoHome?: boolean = false;

  /**
   * optional setting to prevent the user from being redirected to the Manager home screen
   * if if there is no tool instance to switch to in current org
   */
  @property({ type: Boolean, reflect: false }) preventRedirect?: boolean =
    false;

  @property({ type: Boolean, reflect: false }) shouldUpdateUser?: boolean =
    false;

  @property() onManagerToolClick?: (route: string) => void;

  @watch(['shouldUpdateUser', 'onManagerToolClick', 'org', 'app'])
  onPropsChanged() {
    this.init();
  }

  private onProjectSelect(selection: string | undefined) {
    this.emit('project-select', { detail: { selection } });
  }

  constructor() {
    super();
    this.init = this.init.bind(this);
  }

  async connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();

    this.init();

    smartService.subscribe((smartState) => {
      const diff = getStateChanges<SmartContext>(
        smartState.context,
        this.context
      );

      if (diff) {
        this.context = smartState.context;
        console.log('smartState.context', smartState.context, diff);
      }

      if (
        this.app !== ProductName.Organization &&
        !this.preventRedirect &&
        smartState.context.hasAvailableTools === false
      ) {
        smartService.send({
          type: 'resetHasAvailableTools',
        });
        handleGoHome(smartState.context.accountId);
      }
    });
  }

  init() {
    if (this.org && this.context?.accountId !== this.org) {
      smartService.send({
        type: 'changeOrg',
        org: this.org,
      });
    }

    if (this.app !== this.context?.app) {
      smartService.send({
        type: 'changeApp',
        app: this.app,
      });
    }

    if (this.shouldUpdateUser) {
      smartService.send('loadUser');
    }
  }

  render() {
    return html`
      <ps-nav>
        <!-- <slot name="logo"></slot> -->
        <ps-mega-menu
          .app=${this.app}
          .project=${this.project}
          ?hidegohome=${this.hideGoHome}
          .onProjectSelect=${this.onProjectSelect}
          .onManagerToolClick=${this.onManagerToolClick}
        ></ps-mega-menu>
        <slot name="navigation"></slot>
        <slot name="extra"></slot>
        <slot name="controls"></slot>

        <ps-nav-actions>
          <slot name="actions"></slot>
        </ps-nav-actions>

        <slot></slot>
      </ps-nav>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-universal-nav': UniversalNavWC;
  }
  enum PSElementTagNameMap {
    'ps-universal-nav' = 'ps-universal-nav',
  }
}
